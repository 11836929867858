import React from 'react';
import Layout from '../../components/Layout/Layout';
import HomeLayout from '../../components/HomeLayout/HomeLayout';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from "react-responsive"

import Img from "gatsby-image"

const IT = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
    const data = useStaticQuery(graphql`
    query {
      IT: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "IT" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
    return (
        <Layout>
            <HomeLayout>
              {!isTabletOrMobileDevice?<div></div>:<></>}
                {
                  
                    data.IT.nodes.map(img=> <Img
                        fluid={img.childImageSharp.fluid}
                        alt="IT images"
                        key={img.id}
                      />)
                }
                <div style={{opacity:0}}>
                  <Img fluid={data.IT.nodes[0].childImageSharp.fluid} />
                </div>
                <div style={{opacity:0}}>
                  <Img fluid={data.IT.nodes[0].childImageSharp.fluid} />
                </div>
                <div style={{opacity:0}}>
                  <Img fluid={data.IT.nodes[0].childImageSharp.fluid} />
                </div>
                <div style={{opacity:0}}>
                  <Img fluid={data.IT.nodes[0].childImageSharp.fluid} />
                </div>
                <div style={{opacity:0}}>
                  <Img fluid={data.IT.nodes[0].childImageSharp.fluid} />
                </div>
                 
            </HomeLayout>
        </Layout>
    );
};

export default IT;